<template lang="pug">
    div.pa-10#produto
        PageTitle(label="Usuários")

        div(v-acl.cant.hide='`user.list`') Você não possui a permissão necessária para acessar o recurso
        div(v-acl.can.hide='`user.list`')
            v-data-table( 
                fixed-header
                :headers="headers"
                :items="users.data"
                :items-per-page="users.per_page"
                class="elevation-1"
                :loading='users.data.length <= 0'
                loading-text="Carregando... Aguarde!"
                hide-default-footer
                )
                
                template(v-slot:item.created_at="{ item }")
                    small(style="white-space:nowrap") {{item.created_at | formatDate}}
                
                
                template(v-slot:item.actions="{ item }")      
                    div(style="white-space:nowrap")          
                        
                        v-icon(
                            small
                            class="mr-2"
                            @click="editItem(item)"
                            title="Editar"
                            v-acl.can.disable='`user.edit`'
                        ) mdi-pencil
                        
                        v-icon(
                            small
                            @click="deleteItem(item)"
                            title="Deletar"
                            v-acl.can.disable='`user.delete`'
                        )  mdi-delete
            paginate( :data="users" )
            confirm(ref="confirm")

</template>

<script>

export default {
    name: "UsersList",
    components:{
        confirm: () => import( '@/components/Confirm' ),
        paginate: () => import( '@/components/Paginate' ),
        PageTitle: () => import( '@/components/PageTitle' ),
    },

    data(){
        return {
            params: {},
            headers: [
                {
                    text: '#',
                    value: 'id',
                    sortable: false,
                    width: '1%',
                },
                {   text: 'DATA', value: 'created_at', align: 'center', sortable: false, width: '1%',  },
                {   text: 'NOME', value: 'name', sortable: false, width: '1%',  },
                {   text: 'SOBRENOME', value: 'lastname', align: 'center', sortable: false, width: '1%',  },
                {   text: 'LOGIN', value: 'login', align: 'center', sortable: false, width: '1%',  },
                {   text: 'EMAIL', value: 'email', align: 'center', sortable: false,  },
                {   text: '', value: 'actions', sortable: false, width: '1%', },
            ],
            users: {
                data: []
            }
        }
    },

    created: function() {
        this.params = {...this.$route.query}// clone prevent bug
        this.loadData()
    },

    methods: {
        loadData: async function(){
            if ( this.acl('user.list') ) {
                let res = await this.api.get( 'users', this.params )
                this.users = res.data
            }

        },

        editItem: function( item ){
            this.$router.push( { name: 'users-edit', params: { id: item.id } })
        },

        deleteItem: async function( item ){
            if( await this.$refs.confirm.open( 'Confirmação de Exclusão', 'Deseja realmente excluir o item selecionado?' ) ){
              let self = this;
              this.api.delete( `user/${item.id}` ).then(function( res ){
                  if( res ){
                    self.$root.toast.show( {message: res.data.message} )
                    self.loadData()
                  }
              })
            }
        },
    },

    


};
</script>
